<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Adventure starts here 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Make your app management easy and fun!
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="handleRegister"
            >
              <!-- name -->
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="firstName"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Chill Store"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="lastName"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Chill Store"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Description -->
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- phone -->
              <div style="display: flex;align-items: center;justify-content: center">
                <div class="" style="width: 30%">
                  <label for="">Region Code</label>
                  <div class="form-group">
                    <select
                        v-model="subPhone"
                        class="form-control"
                    >
                      <option
                          v-for="(item) in listSubPhone"
                          :value="item.value"
                      >
                        {{ item.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <b-form-group
                    style="width: 70%;margin-top: 4px"
                    label="Phone"
                    label-for="phone"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="phone"
                      vid="phone"
                      rules="required"
                  >
                    <b-form-input
                        id="phone"
                        v-model="phone"
                        name="phone"
                        :state="errors.length > 0 ? false:null"
                        placeholder="888820020"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to privacy policy & terms
                  <!--                  <b-link></b-link>-->
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      locations: [],
      field: 'name',
      firstName: '',
      lastName: '',
      search: '',
      toggle: false,
      newLocations: [],
      status: '',
      name: '',
      phone: '',
      subPhone: '+84',
      userEmail: '',
      description: '',
      password: '',
      province: '',
      provinceName: '',
      district: '',
      districtName: '',
      ward: '',
      wardName: '',
      districtDisable: true,
      address: '',
      merchant_type: '3',
      listCate: [],
      listSubPhone: [
        {
          text: '+61',
          value: '+61',
        },
        {
          text: '+84',
          value: '+84',
        },
        {
          text: '+1',
          value: '+1',
        },
        {
          text: '+65',
          value: '+65',
        },
        {
          text: '+49',
          value: '+49',
        },
        {
          text: '+48',
          value: '+48',
        },
      ],
      category: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  watch: {
    search(val) {
      this.newLocations = []
      this.getLocationSearch(this.search).then(res => {
        this.locations = res.data.data
        this.locations.forEach(element => {
          if (this.newLocations.length < 5) { // chỉ lấy 5 item đầu
            this.newLocations.push(element)
          }
        })
      }).catch(e => {
        this.locations = []
      })
    },
  },
  computed: {
    // eslint-disable-next-line no-undef
    ...mapGetters(['getterListProvince', 'getterListDistrict', 'getterListWard']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.getProvince({})
    this.getCategoryArea({}).then(res => {
      if (res.data.status_code === 200) {
        this.listCate = res.data.data
      }
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    ...mapActions(['register', 'getProvince', 'getDistrict', 'getWard', 'getLocationSearch', 'getCategoryArea']),
    handleRegister() {
      const data = {
        email: this.userEmail,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.subPhone + this.phone,
      }
      this.register(data).then(res => {
        console.log('RES data', res)
      })
    },
    onChange(event) {
      const { options } = event.target
      if (options.selectedIndex > -1) {
        this.provinceName = options[options.selectedIndex].getAttribute('provincename')
      }
      this.getDistrict(event.target.value)
      this.districtDisable = false
    },
    onChangeDistrict(event) {
      const { options } = event.target
      if (options.selectedIndex > -1) {
        this.districtName = options[options.selectedIndex].getAttribute('districtname')
      }
      this.getWard(event.target.value)
    },
    onChangeWard(event) {
      const { options } = event.target
      if (options.selectedIndex > -1) {
        this.wardName = options[options.selectedIndex].getAttribute('wardname')
      }
    },
    selectResult(location) {
      this.search = location
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.autocomplete {
  width: 100%;
  position: relative;
  display: inline-block;
}

input {
  border: 1px solid transparent;
  padding: 10px;
  font-size: 16px;
}

input[type=text] {
  width: 100%;
}

input[type=submit] {
  color: #fff;
  cursor: pointer;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  background-color: ghostwhite;
  opacity: 0.9;
  text-align: left;
  padding-left: 20px;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.custom-search-input {
  background-color: unset;
  padding: 0;
  border-radius: 10px;
}
.custom-search-input {
  box-shadow: none !important;
}
.ip-search-disabled{
  background-color: #bba785 !important;
}
.results{
  background: antiquewhite;
  border: 1px solid lightgray;
}
.results .result{
  border-bottom: 1px solid lightgray;
  padding: 5px;
}

</style>
